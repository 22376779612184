var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grindBeans"},[_c('div',{staticClass:"login-top"},[_c('van-image',{attrs:{"width":"86","height":"66","src":_vm.realName.image}}),_c('div',{staticClass:"login-top-Text"},[_vm._v("认证开通淘星妍钱包，即享分佣收益")])],1),_c('div',{staticClass:"grindBeansFrom"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"left-icon":"friends-o","center":"","label":"姓名","placeholder":"请输入姓名","clearable":"","rules":[{ required: true }]},model:{value:(_vm.userInformation.name),callback:function ($$v) {_vm.$set(_vm.userInformation, "name", $$v)},expression:"userInformation.name"}}),_c('van-field',{attrs:{"label":"身份证号码","center":"","left-icon":"orders-o","placeholder":"请输入身份证号码","rules":[
          { required: true },
          { pattern: /^\d{17}[\dXx]$/, message: '请输入正确的身份证号' },
        ]},model:{value:(_vm.userInformation.identityNo),callback:function ($$v) {_vm.$set(_vm.userInformation, "identityNo", $$v)},expression:"userInformation.identityNo"}}),(_vm.vipShow)?_c('van-field',{attrs:{"left-icon":"phone-o","center":"","label":"手机号","placeholder":"请输入手机号","rules":[
          { required: true },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码' },
        ]},model:{value:(_vm.userInformation.phone),callback:function ($$v) {_vm.$set(_vm.userInformation, "phone", $$v)},expression:"userInformation.phone"}}):_vm._e(),(_vm.vipShow)?_c('van-field',{ref:"verificationCodeField",attrs:{"center":"","name":"number","left-icon":"photo-o","label":"图形验证","rules":_vm.customRules,"placeholder":"请输入图形码"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticStyle:{"border":"none"},on:{"click":_vm.refreshCode}},[_c('picture-identify',{attrs:{"identifyCode":_vm.identifyCode}})],1)]},proxy:true}],null,false,1295613206),model:{value:(_vm.pictureCode),callback:function ($$v) {_vm.pictureCode=$$v},expression:"pictureCode"}}):_vm._e(),(_vm.vipShow)?_c('van-field',{attrs:{"left-icon":"smile-comment-o","center":"","label":"请输入验证码","rules":[{ required: true }],"placeholder":_vm.setCodeText},scopedSlots:_vm._u([{key:"button",fn:function(){return [(_vm.setCodeTextFlage)?_c('span',{class:_vm.setCodeTextShow ? 'enable' : '',staticStyle:{"font-size":"12px","color":"#ffa54f"},on:{"click":_vm.getCode}},[_vm._v("获取验证码")]):_c('span',{staticStyle:{"display":"block","width":"40px","color":"#999999"}},[_vm._v(" "+_vm._s(_vm.textContent)+" ")])]},proxy:true}],null,false,2457002978),model:{value:(_vm.userInformation.verificationCode),callback:function ($$v) {_vm.$set(_vm.userInformation, "verificationCode", $$v)},expression:"userInformation.verificationCode"}}):_vm._e(),_c('van-button',{attrs:{"round":"","block":"","color":"#FFA54F","disabled":_vm.btDisable,"native-type":"submit"}},[_vm._v("提交")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }