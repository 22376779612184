<template>
  <div class="grindBeans">
    <!-- 返回 -->
    <!-- title="实名认证" -->
    <div class="login-top">
      <van-image width="86" height="66" :src="realName.image" />
      <div class="login-top-Text">认证开通淘星妍钱包，即享分佣收益</div>
    </div>
    <div class="grindBeansFrom">
      <van-form @submit="onSubmit">
        <van-field
          v-model="userInformation.name"
          left-icon="friends-o"
          center
          label="姓名"
          placeholder="请输入姓名"
          clearable
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="userInformation.identityNo"
          label="身份证号码"
          center
          left-icon="orders-o"
          placeholder="请输入身份证号码"
          :rules="[
            { required: true },
            { pattern: /^\d{17}[\dXx]$/, message: '请输入正确的身份证号' },
          ]"
        />
        <van-field
          v-if="vipShow"
          v-model="userInformation.phone"
          left-icon="phone-o"
          center
          label="手机号"
          placeholder="请输入手机号"
          :rules="[
            { required: true },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码' },
          ]"
        />
        <!-- 数字验证 -->
        <van-field
          v-if="vipShow"
          v-model="pictureCode"
          center
          name="number"
          left-icon="photo-o"
          label="图形验证"
          :rules="customRules"
          placeholder="请输入图形码"
          ref="verificationCodeField"
        >
          <template #button>
            <div style="border: none" @click="refreshCode">
              <!-- 3. 使用 identify 组件 -->
              <picture-identify :identifyCode="identifyCode"></picture-identify>
            </div>
          </template>
        </van-field>
        <!-- 验证码 -->

        <van-field
          v-if="vipShow"
          left-icon="smile-comment-o"
          v-model="userInformation.verificationCode"
          center
          label="请输入验证码"
          :rules="[{ required: true }]"
          :placeholder="setCodeText"
        >
          <template #button>
            <span
              :class="setCodeTextShow ? 'enable' : ''"
              v-if="setCodeTextFlage"
              style="font-size: 12px; color: #ffa54f"
              @click="getCode"
              >获取验证码</span
            >
            <span v-else style="display: block; width: 40px; color: #999999">
              {{ textContent }}
            </span>
          </template>
        </van-field>

        <van-button
          round
          block
          color="#FFA54F"
          :disabled="btDisable"
          native-type="submit"
          >提交</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
// 1. 引入图片验证插件identify
import PictureIdentify from "../inforMationAuthentiCation/identify.vue";
import { Toast, Notify } from "vant";
import {
  getUserTextCode,
  setCheckuserInformtion,
  setCheckuserVip,
} from "@/api/inforMationAuthentiCation/index";
export default {
  name: "myGrindBeans",
  components: {
    PictureIdentify,
  },
  data() {
    return {
      userInformation: {
        phone: "",
        verificationCode: "",
        name: "",
        identityType: "1",
        identityNo: "",
      },
      vipShow: true,
      btDisable: false,
      disabledCode: false,
      pattern: /^[1][3-9]\d{9}$/,
      setCodeText: "请输入验证码",
      setCodeTextFlage: true,
      setCodeTextShow: false,
      setCodeTextTimer: null,
      textContent: "60秒",
      countdown: 60,
      // ../../assets/实名认证底图.png
      realName: {
        image: require("../../assets/auditingState/实名认证底图.png"),
      },
      pictureCode: "", //输入input框的验证码
      identifyCode: "", //存储的图片验证码
      customRules: [
        {
          field: "codeField",
          validator: (val) => {
            if (val && val == this.identifyCode) {
              this.disabledCode = true;
              return true;
            } else {
              return false;
            }
          },
          trigger: "blur", // 触发校验的时机
        },
        {
          required: true,
          message: "请输入数字校验",
        },
      ],
    };
  },
  created() {
    // 用户唯一标识获取
    // 解析唯一标识
    if (this.$route.query) {
      document.title = "实名认证";
      Object.assign(this.userInformation, {
        bizUserId: `user-${this.$route.query.bizUserId}`,
      });
      this.setCheckuserVip();
    }
  },
  mounted() {
    this.refreshCode();
  },
  methods: {
    // 校验是否会员存在
    setCheckuserVip() {
      setCheckuserVip(
        this.$route.query.bizUserId
      )
        .then((res) => {
          if (res.data.code == "10000" && res.data.subCode == "OK") {
            this.vipShow = false
            Notify({
              type: "warning",
              message: '会员手机号已绑定，请填写实名信息',
            });
          }
        })
        .catch(() => {
          Notify({
              type: "warning",
              message: '网络异常，请重试',
            });
        });
    },
    // 提交按钮接口
    onSubmit() {
      this.btDisable = true;
      // 提交之间清除60s延时器
      clearInterval(this.setCodeTextTimer);
      setCheckuserInformtion(
        this.$route.query.bizUserId,
        this.userInformation
      )
        .then((res) => {
          if (res.data.code == "10000" && res.data.subCode == "OK") {
            this.$router.push({
              name: "resultInforCation",
              query: { resultStatus: 1 },
            });
            this.$tools.setTimeMiao(() => {
              this.btDisable = false;
            }, 2000);
            // 调用成功回调函数
            // handleSuccess(res.data);
          } else {
            Notify({
              type: "warning",
              message: res.data.subMsg || res.data.msg,
            });
            this.$tools.setTimeMiao(() => {
              this.btDisable = false;
            }, 2000);
          }
        })
        .catch((error) => {
          Toast("实名验证失败");
          this.$tools.setTimeMiao(() => {
            this.btDisable = false;
          }, 2000);
          return error;
        });
    },
    // // 定义接口调用成功后的回调函数,接口成功参数
    handleSuccess(data) {
      return data;
    },
    // 验证码
    getCode() {
      // 触发条形码输入框的自身校验
      this.$refs.verificationCodeField.validate().then((res) => {
        if (res) return;
        this.getCodeUrl();
      });
    },
    // 个人会员注册并绑定手机申请  验证码获取
    getCodeUrl() {
      this.setCodeTextShow = true;
      getUserTextCode(this.$route.query.bizUserId, this.userInformation.phone)
        .then((res) => {
          if (res.data.code == "10000" && res.data.subCode == "OK") {
            Toast("验证码发送成功");
            this.setCodeTextShow = false;
            this.setCodeTextFlage = false;
            // 60S倒计时
            // 每秒减少倒计时，并更新页面显示
            clearInterval(this.setCodeTextTimer);
            this.setCodeTextTimer = setInterval(() => {
              this.setCodeTextFlage = false;
              this.countdown--;
              this.textContent = this.countdown + " 秒";

              // 倒计时结束
              if (this.countdown <= 0) {
                // 清除计时器
                clearInterval(this.setCodeTextTimer);
                // 启用发送验证码按钮
                // 重置倒计时时间
                this.countdown = 60;
                // 更新页面显示
                this.textContent = "";
                this.setCodeTextFlage = true;
              }
            }, 1000);
          } else if (res.data.subCode == "30000") {
            Notify({ type: "warning", message: "网络异常请重试" });
          } else {
            Notify({ type: "warning", message: res.data.subMsg || "请重试" });
            this.setCodeTextShow = false;
          }
        })
        .catch((error) => {
          this.setCodeTextFlage = true;
          Toast("验证码发送失败");
          clearInterval(this.setCodeTextTimer);
          this.setCodeTextShow = false;
          return error;
        });
    },
    // 获取随机图片验证码
    refreshCode() {
      let numCode = "";
      for (var i = 0; i < 4; i++) {
        numCode += Math.floor(Math.random() * 10);
      }
      this.identifyCode = numCode;
    },
  },
  destoryd() {
    // 销毁定时器防
    clearInterval(this.setCodeTextTimer);
  },
};
</script>
<style scoped lang="scss">
.van-nav-bar__placeholder::v-deep .van-nav-bar {
  background-color: #fff5e2 !important;
}
.grindBeans {
  height: 100vh;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
}

.login-top {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff5e3;
  .login-top-Text {
    width: 100vw;
    font-size: 12px;
    color: #ffa66d;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    background-color: #ffeed0;
  }
}
.grindBeansFrom {
  position: relative;
  padding-top: 10px;
  height: 100%;
  .van-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .van-cell {
      width: 100%;
      height: 50px;
      margin-top: 1px;
      justify-content: center;
    }
  }
  .van-button {
    width: 90%;
    margin-top: 20px;
  }
}
.btDisable {
  cursor: not-allowed;
}
.enable {
  pointer-events: none;
  color: #e2e2e2 !important;
}
</style>
<style  lang="scss">
</style>
