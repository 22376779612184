import axios from 'axios';
import { getUserToken } from "../untils/localStorage";
import router from '../router'; // 假设你的路由实例在这个路径下


 // 创建基础地址
 const service = axios.create({
  // process.env.NODE_ENV  === 'development'? 'http://192.168.1.3:8082' : 本地开发环境
  baseURL: 'https://api.taoxingyan.com/admin', // 设置 baseURL  正式环境
  // baseURL:   'http://192.168.1.46:8082/api',
  //  withCredentials: true,
   timeout: 5000
 })
export default service;

// 请求拦截器发送之前携带参数
// 在请求发送之前，添加请求拦截器
service.interceptors.request.use(
  config => {
    // 这边做判断需求，如果要自己在封装接口那边加token，请设置AuthorFlage为
      if (config.headers.AuthorFlage){
        return config;
      }else{
        let token = getUserToken() || null
      //在请求头中添加 Authorization 参数，假设 token 是你的用户认证令牌
     // 获取本地用户参数
        // config.headers['Origin'] = 'http://pbimd3.natappfree.cc';
        config.headers.Authorization = `${token}`;
        return config;
      }
      
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 请求拦截器返回之后
service.interceptors.response.use(response => {
 let {code} = response.data
 if(code === 401){
   // token过期了，直接跳转到登录页 
   router.push('/login')
 }
  return response
}, error => {
  return Promise.reject(error);
})
