import request from '@/untils/request'


//获取验证码
// bizUserId,phone
// 获取验证码   个人会员注册并绑定手机申请
export function getUserTextCode(authorization,phone) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianMember/memberBindingPhoneRequest',
      headers: {
        AuthorFlage:true,
        Authorization: authorization
      },
      data:{
        phone
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
// 校验手机验证码+实名认证
export function setCheckuserInformtion(Authorization,data) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      headers: {
        AuthorFlage:true,
        Authorization: Authorization
      },
      url: '/TongLianMember/memberBindingPhoneNotarize',
      data
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}

export function setCheckuserVip(Authorization) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      headers: {
        AuthorFlage:true,
        Authorization: Authorization
      },
      url: '/TongLianMember/getMemberInfoOfToken'
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}