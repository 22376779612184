/* 操作localStorage 储存个人token信息 */
const USER_TOKEN = 'userToken'
// 储存银行卡号
const USER_BLANK_CARID = 'userBlankCarId'
/* 储存 个人 信息 列表 */
export function setUserToken(userToken) {
  localStorage.setItem(USER_TOKEN, JSON.stringify(userToken))
}
/* 拿 商品 信息 列表 */
export function getUserToken() {
  return JSON.parse(localStorage.getItem(USER_TOKEN)) 
}
/* 删除 商品 信息 列表 */
export function removeUserToken() {
  localStorage.removeItem(USER_TOKEN)
}
// 储存银行卡号
export function setUserBlankCarId(userBlankCarId) {
  localStorage.setItem(USER_BLANK_CARID, JSON.stringify(userBlankCarId))
}
export function getUserBlankCarId() {
  return JSON.parse(localStorage.getItem(USER_BLANK_CARID)) 
}
/* 删除密码卡 */
export function removeUserBlankCarId() {
  localStorage.removeItem(USER_BLANK_CARID)
}